//
// Polyfills for using .matches() and .closest()
// --------------------------------------------------
// Adds support for IE 11 to use them.
// We can remove this file when we drop IE 11 support
// --------------------------------------------------
// via https://developer.mozilla.org/en-US/docs/Web/api/element/matches#Polyfill
//  + https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
// --------------------------------------------------

// Matches polyfill
// ---------------------------------------
if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
                                Element.prototype.webkitMatchesSelector;
}


// Closest polyfill
// ---------------------------------------
// IE11 requires the above Matches
// polyfill for this to work
// ---------------------------------------
if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        var el = this;

        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}
