//
// Child Nav Open With Parent Click
// --------------------------------------------------
// If a nav link set to open its child is clicked,
// open the child menu.
// --------------------------------------------------
// Assumes an integration with:
// - navigation-system.js
// --------------------------------------------------
// Do not use alongside child-nav-open-with-generated-button.js
// --------------------------------------------------


// Variables
// ---------------------------------------
var $nav_link_opens_child 		= $('.global-nav-item--opens-child > .global-nav-link');
// ---------------------------------------


// Logic
// ---------------------------------------
$nav_link_opens_child.click(function(e) {
	// Prevent link from being followed
	e.preventDefault();

	// set the parent
	var $the_parent = $(this).parent();

	// remove is-open class if it's already there
	if ( $the_parent.hasClass('submenu-is-open') || $the_parent.hasClass('submenu-is-mobile-open') ) {
		$the_parent.removeClass('submenu-is-open submenu-is-mobile-open')
			.children('.global-nav-child-list').removeClass('is-open');
		$(this).blur();
	}
	// otherwise, add it
	else {
		$the_parent.addClass('submenu-is-open')
			.children('.global-nav-child-list').addClass('is-open');
	}
});
