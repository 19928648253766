//
// DOM loaded body class
// --------------------------------------------------
// When the page is loaded, add & remove classes from the body element
// --------------------------------------------------
// H/t to https://www.sitepoint.com/jquery-document-ready-plain-javascript/
// --------------------------------------------------
var domLoadedCallback = function(){
	document.body.classList.add('dom-has-loaded');
	document.body.classList.remove('dom-not-yet-loaded');
};

if ( document.readyState === "complete" || (document.readyState !== "loading" && !document.documentElement.doScroll) ) {
	domLoadedCallback();
} else {
	document.addEventListener("DOMContentLoaded", domLoadedCallback);
}
