// luhn extra validators
window.ParsleyConfig = window.ParsleyConfig || {};
window.ParsleyConfig.validators = window.ParsleyConfig.validators || {};

window.ParsleyConfig.validators.luhn = {
  fn: function (value) {
    value = value.replace(/[ -]/g, '');
    var digit;
    var n;
    var _j;
    var _len1;
    var _ref2;
    var sum = 0;
    _ref2 = value.split('').reverse();
    for (n = _j = 0, _len1 = _ref2.length; _j < _len1; n = ++_j) {
      digit = _ref2[n];
      digit = +digit;
      if (n % 2) {
        digit *= 2;
        if (digit < 10) {
          sum += digit;
        } else {
          sum += digit - 9;
        }
      } else {
        sum += digit;
      }
    }
    return sum % 10 === 0;
  },
  priority: 32
};
