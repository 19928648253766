//
// Polyfill for using forEach() on NodeLists
// --------------------------------------------------
// Adds support for IE 11 without calling in a 
// Babel dumptruck.
// --------------------------------------------------
// via https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach#Polyfill
// --------------------------------------------------
if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = function (callback, thisArg) {
		thisArg = thisArg || window;
		for (var i = 0; i < this.length; i++) {
			callback.call(thisArg, this[i], i, this);
		}
	};
}
