//
// Perch Navigation / Menu System
// --------------------------------------------------
// Variables defined in perch-core-scripts.js
// --------------------------------------------------


// Variables
// ---------------------------------------
var $global_header 			= $('#global-header'),

	$nav_outer_wrapper 		= $('#global-outer-navigation-wrapper'),

	// $nav_parent 			= $('.global-nav-item--has-children'),
	$nav_active_parent 		= $('.global-nav-item--has-children.active-parent'),
	// $nav_parent_link 		= $('.global-nav-item--has-children > .global-nav-link'),
	$nav_child 				= $('.global-nav-child-list'),

	$nav_reveal_button 		= $('.hamburger-menu-button');


// Functions
// ---------------------------------------


// Mobile nav opening
// --------------------------
function openMobileNav () {
	$('html').addClass('mobile-nav-is-open is-mobile-scroll-locked');
	$nav_reveal_button.addClass('is-active').attr('aria-expanded', 'true').attr('aria-pressed', 'true');
	$nav_outer_wrapper.addClass('is-active');
	$global_header.addClass('mobile-nav-is-open');
}


// Navigation opening
// --------------------------
// Opens the menu for both mobile and desktop.
// Also can accept an argument to open a specific child menu.
// 	> Could be used to open the navigation by clicking a link or button on the page.
// 	> Was once used on WMU/WordlCrafts sidebar
// --------------------------
function openNav (the_menu) {

	// Use interpolation to take the passed child menu name and then select it below
	var interpolated_selector = ".menu-" + the_menu;
	$(interpolated_selector)
		.focus()
		.addClass('is-open submenu-is-open');
	$(interpolated_selector).children($nav_child).addClass('is-open');
	$(interpolated_selector).children('.child-nav-opener').addClass('is-active');

	// Feedback to the body: a child menu is open. Allows for making a backdrop show up over the content/beneath the menu.
	// $('body').addClass('child-menu-is-open');

	openMobileNav();

	if($nav_outer_wrapper.hasClass('navigation-drawer-wrapper')) {
		drawerListenForClosingSwipe();
	}
}


// Navigation closing
// --------------------------
function closeNav (closeNavMilliseconds) {
	$('html').removeClass('mobile-nav-is-open is-mobile-scroll-locked');
	$nav_outer_wrapper.addClass('animating-out');
	$nav_reveal_button.removeClass('is-active').attr('aria-expanded', 'false').attr('aria-pressed', 'false');
	$global_header.removeClass('mobile-nav-is-open');

	// Close megamenus for mobile...before animation is finished
	$('.global-nav-item').removeClass('is-open submenu-is-open submenu-is-mobile-open');
	$('body').removeClass('child-menu-is-open');

	// if a custom timeout is passed, 
	milliseconds = (closeNavMilliseconds >= 0) ? closeNavMilliseconds : 250;

	var nav_close_timer = setTimeout(function() {
		// Close megamenus for mobile...after animation is finished
		$('.child-nav-opener').removeClass('is-active');
		$nav_child.removeClass('is-open');

		$nav_outer_wrapper.removeClass('is-active');
		$nav_outer_wrapper.removeClass('animating-out');
	}, milliseconds);

	// also close the search
	if (typeof closeSearch === 'function') {
		closeSearch();
	}

	// if a drawer, pass the false value to remove the swipe event listeners
	if($nav_outer_wrapper.hasClass('navigation-drawer-wrapper')) {
		drawerListenForClosingSwipe(false);
	}

	return false;
}


// Logic
// ---------------------------------------

// Navigation
// --------------------------

// Close the menu if it's currently active. Otherwise: run openNav function.
$nav_reveal_button.on('click keydown', function(e) {
	if ((e.type == 'click') || (e.keyCode == 32)) {
		e.preventDefault();

		if ($(this).hasClass('is-active')) {
			closeNav();
			this.blur(); // Remove the focus from the button so :focus styles don't show on touch screens

		} else {
			openNav();
		}
	}
});
