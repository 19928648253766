//
// Keyboard shortcuts with MouseTrap.js
// --------------------------------------------------
// Assumes an integration with:
// - navigation-system.js
// - modal-search-system.js
// --------------------------------------------------

// open search
Mousetrap.bind('/', function(e) {

	if (typeof openSearch == 'function' && $search_menu.length) {
		// If the search menu is already open, nevermind, let the `/` through...
		if ($search_menu.hasClass('is-active')) {
			return true;
		}

		// But if it's not already open, capture the `/` and open the menu
		else {
			// Prevent '/' char from being typed into the newly-focused search field
			e.preventDefault();

			openSearch();
		}
	}
	// else {
	// 	console.log("Either openSearch is not a function or $search_menu has not been assigned.");
	// }

});

// press escape (ESC) to close menus
Mousetrap.bind('esc', function() {
	if (typeof closeNav == 'function') {
		closeNav();
	}
	if (typeof closeSearch == 'function') {
		closeSearch();
	}
});

// open the menu/nav
Mousetrap.bind('u', function() {
	// Since search input is allowed to listen to Mousetrap (normally it's disabled — but we want it enabled for ESC key close), ignore the "u" character if it is open
	if( (!typeof($search_menu) === 'undefined') && ($search_menu.hasClass('is-active')) ) {
		return true;
	}

	// Otherwise, if the mobile navigation is open, close it
	else if ($global_header.hasClass('mobile-nav-is-open')) {
		if (typeof closeNav == 'function') {
			closeNav();
		}
	}

	// ...and if it's not open, open it
	else {
		if (typeof openNav == 'function') {
			openNav();
		}
	}
});

// // konami code!
// Mousetrap.bind('up up down down left right left right b a', function() { //enter
// 	document.body.classList.add('konami-code-is-active');
// });
