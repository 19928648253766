//
// Dispatcher.js
// v0.4.4
// ----------------------------------------------------------------------------
// Create actions & reactions in markup
// ----------------------------------------------------------------------------
// TODO
// - Change data-dispatcher-notify to accept an array of IDs to notify
// - Allow customization of .is-dispatcher-notified class name (for multiple uses of dispatcher)
// - Separate trigger, target, and notified dispatch into 3 functions?
// - Add a callback when dispatch is completed?
// - Rewrite in Vanilla JS (for event delegation, see https://gomakethings.com/checking-event-target-selectors-with-event-bubbling-in-vanilla-javascript/)
// ----------------------------------------------------------------------------


// Functions
// --------------------------------------------------
function clickDispatch (trigger) {
	// Feedback to the trigger element
	// --------------------------
	
	// if an attribute data-dispatcher-trigger-active-class __exists and has a value__
	if ( trigger.attr("data-dispatcher-trigger-active-class") ) {
		var $custom_trigger_class = trigger.data("dispatcher-trigger-active-class");
		trigger.toggleClass($custom_trigger_class);
	}
	else {
		// Toggle active class
		trigger.toggleClass("is-active");
	}

	// Toggle selected ARIA attributes if they exist
	if ( trigger.attr("aria-pressed") )  { var hasAriaPressedAttr  = true; }
	if ( trigger.attr("aria-expanded") ) { var hasAriaExpandedAttr = true; }

	if (hasAriaPressedAttr) {
		trigger.attr("aria-pressed",  (trigger.attr("aria-pressed")  == "false" ? true : false));
	}
	if (hasAriaExpandedAttr) {
		trigger.attr("aria-expanded", (trigger.attr("aria-expanded") == "false" ? true : false));
	}


	// Notified element dispatch
	// --------------------------

	// If an element is to be notified (a value is given in the attribute),
	// notify it/them with toggled class "is-dispatching"
	if ( trigger.attr("data-dispatcher-notify") ) {
		var $notify_class = "." + trigger.data("dispatcher-notify");
		$($notify_class).toggleClass("is-dispatcher-notified");
	}


	// Target element dispatch
	// --------------------------

	// Get the targeted element via data-attribute
	var target = "#" + trigger.data("dispatcher-target");
	target = $(target);

	// if an attribute data-dispatcher-target-active-class __exists and has a value__
	if ( trigger.attr("data-dispatcher-target-active-class") ) {
		var $custom_target_class = trigger.data("dispatcher-target-active-class");
		target.toggleClass($custom_target_class);
	}
	else {
		target.toggleClass("is-active");
	}

	// ARIA attributes to flip
	target.attr("aria-hidden", (target.attr("aria-hidden") == "false" ? true : false));


	// "One at a time, siblings" dispatch
	// --------------------------
	// For when only one child may be active at a time. (Like an accordion!)
	// --------------------------
	if ( trigger.attr("data-dispatcher-parent") ) {
		// Get the targeted element via data-attribute
		var $parent = "#" + trigger.data("dispatcher-parent");

		// These may _seem_ reversed, but note that at the top of this function, the is-active class is toggled. So at this point, we're not checking "if the trigger had is-active before click", but "if the trigger has is-active now that it has been clicked".
		if ( trigger.hasClass("is-active") ) {
			$($parent).addClass("has-dispatched-active-child");
		}
		else {
			$($parent).removeClass("has-dispatched-active-child");
		}

		trigger.siblings(".dispatcher-trigger").removeClass("is-active");
	}
}


// Logic
// --------------------------------------------------
$(document).ready(function() {
	// listen for a click or keydown event on elements with .dispatch-trigger. keydown is needed because some elements that are not native <button> or <a> elements — yet have tabindex="0" added allowing for keyboard access — need to receive a spacebar key press or return key press
	$("body").on("click keydown", ".dispatcher-trigger", function(event) {

		// Check if the event is either a click, a spacebar press, or a return press
		if ( (event.type === "click") || (event.which == 13 || event.which == 32)) {

			// restricts the "overpowered" preventDefault action ONLY to a direct click/keydown on .dispatch-triger element
			//   - this retains blocking of default actions when the trigger is an anchor itself
			//   - but allows child a, input, button, label, etc. to retain their functionality
			if(event.target === event.currentTarget) {
				// Prevent default click actions
				event.preventDefault();
			}
			
			// Fire the main function
			clickDispatch( $(this) );
		}
	});
});
