//
// Passive option for event listeners
// --------------------------------------------------
// Feature detection for addEventListener's 'passive' option.
// Allows for conditional setting of the third argument in an event listener.
// --------------------------------------------------
// Example usage:
// el.addEventListener('click', function(){...}, passiveEventListenerOptionSupported ? { passive: true } : false);
// --------------------------------------------------
var passiveEventListenerOptionSupported = false;

try {
	var opts = Object.defineProperty({}, "passive", {
		get: function() {
			passiveEventListenerOptionSupported = true;
		}
	});
	window.addEventListener("testPassiveOption", null, opts);
	window.removeEventListener("testPassiveOption", null, opts);
} catch(err) {}
